import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";

import lockedIcon from "../../images/locked.svg";
import Tippy from "@tippyjs/react";
import infoIcon from "../../images/info.svg";
import infoFilledIcon from "../../images/info-filled.svg";


import oldStyles from "./styles.module.scss";
import processStyles from "../../utils/processStyles";
const styles = processStyles(oldStyles);

export interface ToplineSummaryProps {
  airings?: number;
};

export interface SummaryCardProps {
  title: string;
  value?: string | number;
  locked?: boolean;
  tooltip?: string;
  handleMouseEnter?: () => void;
  handleMouseLeave?: () => void;
};


export const InfoButton: React.FC<{children: React.ReactNode}> = (props) => {
  const [isActive, setIsActive] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsActive(false);
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [ setIsActive ]);

  return (
    <Tippy
      placement="top"
      theme="dark"
      visible={isActive}
      maxWidth={260}
      content={props?.children}
    >
      <button type="button" onClick={() => { setIsActive(!isActive);}} className={styles.infoButtonContainer}>
        { <img src={isActive? infoFilledIcon : infoIcon} className={styles.infoIcon} alt="info icon"/> }
      </button>
    </Tippy>
  );
};

const SummaryCard: React.FC<(SummaryCardProps)> = ({title, value, locked = false, tooltip, handleMouseEnter = () => {}, handleMouseLeave = () => {}}) => {

  let valueDiv = <div className={styles.summaryCardValue}>
    {value}
  </div>;
  if (locked) {
    valueDiv = <div className={styles.summaryCardLock} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        <img src={lockedIcon} alt="locked" />
      </div>;
  } else if (value === null) {
    valueDiv = <div className={styles.summaryCardNoData}>
      No Data Available
    </div>;
  }

  return (
    <>
      <div className={classNames(styles.summaryCardContainer)}>
        <div className={classNames("flex-row", styles.summaryCardTitle)}>
          {title}
          { tooltip && 
            <InfoButton >
              <div className={styles.infoButton}>{tooltip}</div>
            </InfoButton>
          }
        </div>
        { valueDiv }
      </div>
    </>
  )
};

const ToplineSummary: React.FC<(ToplineSummaryProps)> = (props) => {

  return (
    <div className={classNames("flex-col flex-wrap uk-flex-between", styles.toplineSummaryCards)}>
      <SummaryCard title={"Ad Engagement vs. Competition"} locked />
      <SummaryCard title={"National Airings"} locked />
      <SummaryCard title={"National Impressions"} locked />
      <SummaryCard title={"Estimated Spend"} locked />
      <SummaryCard title={"# of Creatives in Rotation"} locked />
      <SummaryCard title={"Recently Aired On"} locked />
  </div>
  );
};

export default ToplineSummary;
