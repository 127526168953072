import React from "react";
import classNames from "classnames";
import oldStyles from "./index.module.scss";
import EmailForm from "../email_form";
import ToplineSummary from "../topline_summary/ToplineSummary";
import processStyles from "../../utils/processStyles";
const styles = processStyles(oldStyles);

const LockedMetricsDisplay: React.FC<{}> = ()  => {
  return (
    <div className={classNames("",styles.container)}>
      <div className={classNames("flex-col", styles.innerContainer)}>
        <div className={classNames("uk-flex-between", styles.header)}>
          <div className={styles.emailLabel} >Get access to TV ad performance data</div>
        </div>
        <ToplineSummary />
        <div className={classNames("", styles.emailForm)}>
          <EmailForm />
        </div>
      </div>
    </div>
  )
}

export default LockedMetricsDisplay;
