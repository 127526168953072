import React from "react";
import classNames from "classnames";
import oldStyles from "./index.module.scss";
import logo from "../../images/edo-logo-white.svg";
import processStyles from "../../utils/processStyles";
const styles = processStyles(oldStyles);


const PRODUCT_LINKS =  [
  { label: "Ad EnGage Platform", url: "/advertisers/ad-engage-convergent" },
  { label: "Competitive Intelligence", url: "/advertisers/competitive-intelligence" },
  { label: "Custom Analytics", url: "/advertisers/custom-analytics" },
  { label: "Predictive Outcomes", url: "/advertisers/predictive-outcomes" },
  { label: "Ad EnGage Data Stream (API)", url: "/advertisers/ad-engage-data-stream" },
  { label: "Box Office", url: "/movies/box-office" },
  { label: "Instant Insights Screenings", url: "/movies/screenings" },
];

const COMPANY_LINKS = [
  { label: "Press", url: "/about-us/press" },
  { label: "About Us", url: "/about-us" },
  { label: "Insights", url: "/resources" }, 
  // { label: "Insights", url: "/resources/case-studies" }, ROUTE HERE WHEN WE HAVE CASE STUDIES
  { label: "Careers", url: "/about-us/careers" },
]
const CONNECT_LINKS = [
  { label: "Contact Us", url: "https://www.edo.com/contact-us" },
  { label: "LinkedIn", url: "https://www.linkedin.com/company/edo-inc/" },
  { label: "Top Ads", url: "https://topads.edo.com/ad-engage/brand-rankings-tv-ad-creatives/" },
]

const Footer: React.FC<{}> = () => {

  const currentYear = new Date().getFullYear();

  return (
    <div className={styles.basicStyle}>
      <div className={classNames("uk-flex uk-flex-center uk-flex-wrap", styles.directory)}>
        <div>
          <a href={"https://edo.com"} className={"uk-logo margin-right-72"} id="footer-home-logo">
            <img className={classNames("uk-flex", styles.logo)} data-src={logo} src={logo} data-uk-img alt={""} />
          </a>
        </div>
        <div className={classNames("uk-flex-inline uk-width-auto", styles.linkSection)}>
          <div className={classNames("uk-grid-1-3 padding-right-32", styles.linksContainer)}>
            <div className={styles.linksHeader}>
              Products
            </div>
            { PRODUCT_LINKS.map((productLink,i) => 
              <div key={i} className={styles.footerLink}><a href={"https://www.edo.com" + productLink.url}>{productLink.label}</a></div>
            )}
          </div>
          <div className={classNames("uk-grid-1-3 padding-right-100", styles.linksContainer)}>
            <div className={styles.linksHeader}>
              Company
            </div>
            { COMPANY_LINKS.map((companyLink, i) => 
              <div key={i} className={styles.footerLink}><a href={"https://www.edo.com" + companyLink.url}>{companyLink.label}</a></div>
            )}
          </div>
          <div className={classNames("uk-grid-1-3", styles.linksContainer)}>
            <div className={styles.linksHeader}>
              Connect With Edo
            </div>
            { CONNECT_LINKS.map((connectLink, i) => 
              <div key={i} className={styles.footerLink}><a href={connectLink.url}>{connectLink.label}</a></div>
            )}
            <div key={"pressInquiry"} className={styles.footerLink}><a href={"mailto:press@edo.com"}>Press Inquiries</a></div>
          </div>
        </div>
      </div>

      <div className={classNames("uk-flex uk-flex-wrap uk-flex-wrap-around ", styles.subFooter)}>
        <div className="uk-flex uk-width-3-5@m uk-text-left uk-flex-wrap uk-flex-wrap-around">
          <div>New York City | Los Angeles | San Francisco</div>
          &nbsp;
          <div>© EDO, Inc. {currentYear}</div>
        </div>
        <div className="uk-width-2-5@m uk-text-right">
          <a href={"https://www.edo.com/legal/privacy-policy"} className={styles.legalLink} id="footer-privacy-policy">Privacy Policy</a>
          {" | "}
          <a href={"https://www.edo.com/legal/terms-and-conditions"}  className={styles.legalLink} id="footer-terms-and-conditions">Terms and Conditions</a>
        </div>
      </div>
    </div>
  );
}

export default Footer;
