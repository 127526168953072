import React from "react";
import classNames from "classnames";
import oldStyles from "./styles.module.scss";
import processStyles from "../../utils/processStyles";
const styles = processStyles(oldStyles);

const LoadingIndicatorInline: React.FC<{ width: number | string, height: number | string }> = (props) => {
    return <div className={classNames(styles.isLoadingInline)} style={props}></div>;
};

export default LoadingIndicatorInline;
