import React from "react";
import classNames from "classnames";
import LoadingIndicatorInline from "./LoadingIndicatorInline";
import oldStyles from "./styles.module.scss";
import processStyles from "../../utils/processStyles";
const styles = processStyles(oldStyles);

const LoadingIndicator: React.FC<{ backgroundColor?: string }> = ({ backgroundColor = "white" }) => {
  return (
    <div className={classNames(styles.loadingIndicatorContainer, "flex-row-around", "flex-align-center")} style={{ backgroundColor }}>
      <LoadingIndicatorInline width="64px" height="64px" />
    </div>
  );
}

export default LoadingIndicator;
