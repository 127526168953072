import React, { Suspense, useEffect, useState } from 'react';
import { getCreativeData } from './utils/invokeLambda';
import Helmet from "react-helmet";
import logo from './logo.svg';
import './App.scss';
import Header from "./components/header";
import Footer from "./components/footer";
import classNames from "classnames";
import LockedMetricsDisplay from './components/locked_metrics_display';
import { LoadingIndicatorInline } from "./components/loading_indicator";
import { CategoryMetadataFile, fetchCategoriesMetaData, fetchCategoryData, CategoryData, BrandData } from "./utils/ad_engage";
import RelatedFooter from "./components/related_footer";
import Creative from './models/creative';
import Icons from 'uikit/dist/js/uikit-icons';
import UIkit from 'uikit';
import "./styles/styles.scss";
import "./styles/uikit.scss";
import InterruptorBar from "./components/interruptor_bar";
import * as Sentry from "@sentry/react";

const VideoSection = React.lazy(() => import('./components/video'));

function App() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [data, setData] = useState<Creative | null>(null);
  const [categoryMetadata, setCategoryMetadata] = useState<CategoryMetadataFile>(null)
  const [relatedCategoryData, setRelatedCategoryData] = useState<Array<CategoryData>>([]);
  const creativeId = window.location.pathname.split("/")[3];

  useEffect(() => {
    if (typeof document !== "undefined") {
      UIkit.use(Icons);
    }
  }, []);

  useEffect(() => {
    if(data === null) {
      Promise.all([
        fetchCategoriesMetaData()
        .then((data) => { 
          setCategoryMetadata(data)
        })
        .catch((err) => { 
          Sentry.captureException(err);
         }),
  
        getCreativeData(creativeId)
        .catch((err) => { 
          Sentry.captureException(err);
         })
        .then((response) => {
          if(response == null || response.length <= 0) {
            setError(true);
            Sentry.captureException(new Error(`No Data for Creative Id: ${creativeId}`));
          } else {
            response[0].creativeId = creativeId;
            setData(response[0]);
          }
        })
      ]).catch(err => {
        Sentry.captureException(err);
      })
      .finally(() => {
        setLoading(false);
      })
    }
  }, []);
  useEffect(() => {
    if (categoryMetadata !== null && data?.categoryId) {
      const originalCategory = data?.categoryId;
      const relatedCategoryIds = [];
      Object.keys(categoryMetadata).forEach((key) => {
        if (categoryMetadata[key]?.category_ids?.indexOf(originalCategory) >= 0) {
          relatedCategoryIds.push(key)
        }
      })
      // fetchBrandData()
      Promise.all(relatedCategoryIds.map((c) => {
        return fetchCategoryData(c).then((data) => {
          return data
        })
      }))
      .catch((err) => {
        Sentry.captureException(err);
      })
      .then((data) => {
        if (data) {
          setRelatedCategoryData(data);
        }
      })
    }

  },
  [data, categoryMetadata])


  const loadingState = <div className={classNames("loading-container", "flex-row-around", "flex-align-center")}>
    <LoadingIndicatorInline width="64px" height="64px" />
  </div>

  const errorState = <div className={"flex-col error-container"}>
    Sorry this video is unavailable.

    <div className={"flex-row error-buttons"}>
      <a className="error-button" href={"https://www.edo.com/ad-engage/brand-rankings-tv-ad-creatives/"}>Explore Top Ads</a>
      <a className="error-button" href={"https://www.edo.com/resources/"}>Explore EDO Resources</a>
      <a className="error-button" href={"https://www.edo.com/contact-us/"}>Contact Us</a>
    </div>
  </div>;


  const loadedState = error ? errorState : <div className="video-metrics-container uk-flex uk-flex-wrap">
    <Suspense fallback={<></>}>
      <VideoSection creative={data!} creativeId={creativeId} />
    </Suspense>

    {/* <div onClick={() => { setError(true) }}
    >
      Click here to trigger error state
    </div> */}
    <LockedMetricsDisplay />
  </div>;

  return (
    <div className="App">
      <Helmet
        titleTemplate="%s"
        defaultTitle="EDO Public Player"
      >
        {data?.title ? <title>{data.advertiser} - {data.title}</title> : null}
        {process.env.EDO_ENV === "production" 
          ? <script>{"(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-5Q2ZG75');"}</script> 
          : null}
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link href="https://fonts.googleapis.com/css?family=Space+Mono:400,700" rel="stylesheet" />
        <link href="https://fonts.googleapis.com/css?family=Open+Sans:400,600,700" rel="stylesheet" />
        <link href="https://fonts.googleapis.com/css?family=Rubik:200,400,500,600,700,800&display=swap" rel="stylesheet" />
        <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.4.1/css/all.css" integrity="sha384-5sAR7xN1Nv6T6+dT2mhtzEpVJvfS3NScPQTrOxhwjIuvcA67KV2R5Jz6kr4abQsz" crossOrigin="anonymous" />
      </Helmet>
      {process.env.EDO_ENV === "production" 
        ? <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-5Q2ZG75" height="0" width="0" style={{ display:"none", visibility:"hidden"}}></iframe></noscript> 
        : null}
      <div>
        <Header />
        {/*<InterruptorBar*/}
        {/*  to={"https://edo.com/superbowl"}*/}
        {/*  label={"Be first to know the ad winners of Super Bowl LVIII."}*/}
        {/*/>*/}
        <div className={"content"}>
          { loading ? loadingState : loadedState }
          { relatedCategoryData.length > 0 ? <RelatedFooter relatedCategoryData={relatedCategoryData} creativeId={creativeId} /> : null }
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default App;
