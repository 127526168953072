import React, { useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import oldStyles from "./index.module.scss";

import "./uk_styles.scss";
import queryString from "query-string";
import { forEachObjIndexed, isEmpty } from "ramda";
import { LoadingIndicatorInline } from "../../components/loading_indicator";
import axios from "axios";
import * as Sentry from "@sentry/react";

import logo from "../../images/EDO-logo-with-tagline.svg";
import Button from "../../components/button";
import processStyles from "../../utils/processStyles";
import { dataLoadingPath, adEngagePath } from "../../utils/ad_engage";
const styles = processStyles(oldStyles);


export const DEFAULT_HEADERS = {
  'Cache-Control': 'max-age=43200, must-revalidate', // 43200 seconds -> 12 hours
  'Accept': "application/json, text/plain, */*",
}

export const TOP_EVENTS = {
  nfl: {
    title: "2023 NFL Season",
    path: "nfl-top-ads",
    sort: "Performance",
    header: "NFL Creatives"
  },
  winterOlympics: {
    title: "2022 Beijing Winter Olympics",
    path: "2022_beijing_winter_olympics",
    sort: "Airings",
    header: "2022 Beijing Winter Olympics"
  },
  // worldCup: {
  //   title: "2022 Men's World Cup",
  //   path: "world-cup",
  //   sort: "Performance",
  //   header: "Men's World Cup Creatives"
  // },
  womensWorldCup: {
    title: "2023 Women's World Cup",
    path: "womens-world-cup",
    sort: "Performance",
    header: "Women's World Cup Creatives"
  }
}
export const CURRENT_TOP_EVENT_KEY = "nfl"

const generateTopEventSidebarItem = () => {
  const sidebarMenuItems: Array<IMenuItem> = [];
  sidebarMenuItems.push({
    path: `/${TOP_EVENTS[CURRENT_TOP_EVENT_KEY].path}`,
    label: TOP_EVENTS[CURRENT_TOP_EVENT_KEY].title,
    newLabel: true,
  });
  return sidebarMenuItems
}

export const getPathFromCategoryMetadata = (categoryId: string, categoryData: CategoryMetadata) => {
  return categoryId + "_" + categoryData?.name?.replace(/[^a-z0-9]/gi,'');
}


const generateCategorySidebarItems = (categoryMetadata: CategoryMetadataFile | null) => {
  const sidebarMenuItems: Array<IMenuItem> = [];
  forEachObjIndexed((categoryData: CategoryMetadata, categoryId: string) => {
    if (categoryId !== "default") {
      sidebarMenuItems.push({
        path: "/" + getPathFromCategoryMetadata(categoryId, categoryData),
        label: categoryData?.name,
      });
    }
  }, categoryMetadata);
  return sidebarMenuItems
}


export const getSidebarMenuItems = (categoryMetadata: CategoryMetadataFile | null = null, setShowModalCallback?: (showModal: boolean) => void, mixpanel?: any): Array<IMenuItem> => {
  let sidebarMenuItems: Array<IMenuItem> = [
    ...generateTopEventSidebarItem(),
    {
      path: `/ad-engage/${TOP_EVENTS.womensWorldCup.path}`,
      label: TOP_EVENTS.womensWorldCup.title,
      absolutePath: true,
    },
    {
      path: `/resources/oscars-2023`,
      label: "2023 Oscars",
      absolutePath: true,
    },
    {
      path: `/resources/superbowl-lvii`,
      label: "Super Bowl LVII",
      absolutePath: true,
    },
    // {
    //   path: `/ad-engage/${TOP_EVENTS.worldCup.path}`,
    //   label: TOP_EVENTS.worldCup.title,
    //   absolutePath: true
    // },
    {
      divider: true
    },
    {
      path: "/",
      label: "Top Brands",
    },
    ...generateCategorySidebarItems(categoryMetadata),
  ];
  if (setShowModalCallback) {
    sidebarMenuItems.push({
      divider: true
    },)
    sidebarMenuItems.push({
      label: "More Categories & Brands",
      onClick: () => {
        setShowModalCallback(true);
        mixpanel.track("Modal - More Categories & Brands")
      },
      bold: true
    });
  }
  return sidebarMenuItems;
};


export interface CategoryMetadata {
  name: string;
}
export interface CategoryMetadataFile {
  [index: string]: CategoryMetadata;
}

interface INavMenuItem {
  label: string;
  url?: string;
  href?: string;
  subMenu?: Array<INavMenuItem>;
  subMenuStyles?: string;
};

export interface IMenuItem {
  locked?: boolean;
  path?: string;
  search?: Object;
  label?: string;
  divider?: boolean;
  bold?: boolean;
  onClick?: () => void;
  active?: boolean;
  newLabel?: boolean;
  absolutePath?: boolean;
}



export const fetchCategoriesMetaData = (): Promise<CategoryMetadataFile> => {
  return axios.get(
    dataLoadingPath + "categories_metadata.json",
    { headers: DEFAULT_HEADERS }
  )
    .then((res: any) => {
      if (typeof res.data != "object") {
        throw new Error("No data");
      }
      if (res.data) {
        /* TODO: check for correct meta data */
        return res.data
      }
    })
}


const MENU: Array<INavMenuItem> = [
  // { label: "For Advertising", url: "/advertisers",
  //   subMenu: [
  //     { label: "Ad EnGage Platform", url: "/advertisers/ad-engage-convergent" },
  //     { label: "Competitive Intelligence", url: "/advertisers/competitive-intelligence" },
  //     { label: "Custom Analytics", url: "/advertisers/custom-analytics" },
  //     { label: "Predictive Outcomes", url: "/advertisers/predictive-outcomes" },
  //     { label: "Ad EnGage Data Stream (API)", url: "/advertisers/ad-engage-data-stream" },
  //   ],
  //   subMenuStyles: "uk-navbar-dropdown-width-2",
  // },
  // { label: "For Movies", url: "/movies",
  //   subMenu: [
  //     { label: "Box Office", url: "/movies/box-office" },
  //     { label: "Instant Insights Screenings", url: "/movies/screenings" },
  //   ]
  // },
  // { label: "Resources", url: "/resources",
  //   subMenu: [
  //     { label: "News", url: "/resources/news" },
  //     { label: "Industry Reports", url: "/resources/industry-reports" },
  //     { label: "Sports & Live Events", url: "/resources/sports-and-live-events" },
  //     { label: "Upfronts", url: "/resources/upfronts" },
  //     { label: "Thought Leadership", url: "/resources/thought-leadership" },
  //     { label: "TV & Film", url: "/resources/tv-and-film" },
  //     { label: "Research & Insights", url: "/resources/research-and-insights" },
  //   ]
  // },
  // { label: "About Us", url: "/about-us",
  //   subMenu: [
  //     { label: "Careers", url: "/about-us/careers" },
  //     { label: "Press", url: "/about-us/press" },
  //   ]
  // },
  // { label: "Contact Us", url: "/contact-us" },
];

const Header: React.FC<{}> = (props)  => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [categoryMetadata, setCategoryMetadata] = useState<CategoryMetadataFile>();
  useEffect(() => {
    fetchCategoriesMetaData()
      .catch((err) => {
        Sentry.captureException(err);
      })
      .then((data) => { if(data) {
        setCategoryMetadata(data)
      }})
  }, []);

  return (
    <nav className={classNames(styles.header)}>
      <div className={classNames(styles.navbarWrapper, "uk-navbar")} data-uk-navbar>
        <div className={classNames("uk-navbar-left", styles.navbarLeft)}>
          <a href={"https://www.edo.com"} className={"uk-logo"} id="navbar-home-logo">
            <img className={styles.headerLogo} data-src={logo} src={logo} data-uk-img alt={""} />
          </a>
        </div>
        <div className="uk-navbar-center">
          <Menu location={""} />
        </div>

        <div className="uk-navbar-right">
          <div className="uk-inline">
            <Button id="top-ads-button"
              href={"https://www.edo.com/ad-engage/brand-rankings-tv-ad-creatives/"}
              className={classNames("margin-left-30", styles.topAdsButton, styles.desktopMenuButton)}>
              {/* <p className={classNames(styles.icon, "uk-icon")} uk-icon={"icon: thumbnails; ratio: 1.2"} /> */}
              <span>
                TOP ADS
              </span>
            </Button>
            <div className={classNames("uk-dropdown", styles.topAdsDropdown)} uk-dropdown="mode:hover">
              <ul className="uk-nav uk-navbar-dropdown-nav">
                { categoryMetadata ? getSidebarMenuItems(categoryMetadata).map((item: IMenuItem, i)=> {
                  const { path, search = {}, label, divider, absolutePath = false } = item;
                  const pathName = absolutePath ? path : adEngagePath + (path || "");
                  const link = "https://edo.com" + pathName + (!isEmpty(search) ? "?" + queryString.stringify(search) : "");
                  if (divider) { 
                    return <hr key={i} className={styles.topAdsDropdownDivider} /> 
                  }
                  return <li key={i}><a href={link} key={i}>
                    {label}
                  </a>
                  </li>
                })
                : <div className={classNames("flex-col", styles.loadingIndicatorContainer)}>
                    <LoadingIndicatorInline width="64px" height="64px" />
                  </div>
              }
              </ul>
            </div>
          </div>

          

          <Button href={"https://www.edo.com/ad-engage/brand-rankings-tv-ad-creatives/"} id="top-ads-mobile-button" className={classNames("uk-icon-button", styles.icon, styles.mobileMenuButton, styles.topAdsButton, styles.topAdsMobileButton)}>
            Top Ads
          </Button>
          <div className="uk-margin-small-left" />
          <div className={styles.mobileMenuButton}>
            <span className={classNames(styles.icon)} uk-icon={mobileMenuOpen ? "icon: close; ratio: 2" :"icon: menu; ratio: 2"} onClick={() => setMobileMenuOpen(!mobileMenuOpen)} />
          </div>
        </div>
      </div>
      {/* Mobile Menu only show on smaller screens */}
      { mobileMenuOpen && <MobileMenu />}
    </nav>
  )
};

// This is the menu for the desktop version
const Menu = (props: { location: any }) => {
  const { location } = props;
  return <ul className={classNames(styles.desktopMenu, "uk-navbar-nav")} id="navbar">
    {MENU.map(({ label, url, href, subMenu, subMenuStyles }, i) => {
      const hasSubMenu = !!subMenu && Array.isArray(subMenu) && subMenu.length > 0;
      return (
        <li key={i} className={classNames({"uk-active": location?.pathname && location?.pathname?.startsWith(url) })}>
          {url &&
            <a id={`navbar-${label}`} href={"https://www.edo.com" + url} style={{fontFamily: "Rubik"}}>
              { label}
              {hasSubMenu && (<i className={classNames("margin-left-0")} uk-icon="icon: chevron-down; ratio: .6"/>)}
            </a>
          }
          {href &&
            <a id={`navbar-href-${label}`} href={href} style={{fontFamily: "Rubik"}}>
              {label}
              {hasSubMenu && (<span className={"margin-left-0"} uk-icon="icon: chevron-down"/>)}
            </a>
          }
          {hasSubMenu && (
            <div className={classNames("uk-navbar-dropdown", styles.dropdown, hasSubMenu ? subMenuStyles : "")} uk-navbar="target-y">
              <ul className="uk-nav uk-navbar-dropdown-nav">
                {subMenu.map(({ label: subMenuLabel, url: subMenuUrl, href: subMenuHref }, j)=> {
                  if (subMenuHref) {
                    return <li key={`${i}-${j}`}>
                      <a id={`navbar-${label}-${subMenuLabel}`} href={subMenuHref} style={{fontFamily: "Rubik"}}>
                        {subMenuLabel}
                      </a>
                    </li>;
                  }
                  return !!subMenuUrl ?
                    (<li key={`${i}-${j}`} className={classNames({"uk-active": location?.pathname && location?.pathname?.startsWith(subMenuUrl)})}><a id={`navbar-${label}-${subMenuLabel}`} href={"https://www.edo.com" + subMenuUrl} style={{fontFamily: "Rubik"}}>{ subMenuLabel }</a></li>)
                    : (<li key={`${i}-${j}`} className={classNames("uk-nav-header")} style={{fontFamily: "Rubik"}}>{ subMenuLabel }</li>);
                })}
              </ul>
            </div>
          )}
        </li>
      )
    })}
  </ul>
};


// This is the menu for mobile version
const MobileMenu = () => {
  let mobileMenuRows: any = [];
  const [subMenu, setSubMenu] = useState<string | null | undefined>(null);

  const subMenuRows = useMemo(() => {
    let rows = [];
    const menuItem = MENU.find((menuItem) => menuItem.url === subMenu);

    if (subMenu && menuItem && !!menuItem?.subMenu) {
      rows.push(
        <li key={"header"}>
          <div className={classNames("uk-grid uk-grid-match", styles.mobileMenuHeaderRow)}>
            <span className="padding-left-0" onClick={() => {setSubMenu("")}} >
              <i className={classNames(styles.mobileSubMenuArrow)} uk-icon={"icon: chevron-left;"} />
            </span>
            { menuItem.url ?
              <div className={classNames("uk-width-expand", styles.mobileSubMenuHeader)}>
                <a id={`mobile-navbar-${menuItem.label}`} className={classNames("uk-nav-header", styles.mobileMenuLink)} href={"https://www.edo.com" + menuItem.url}>
                  {menuItem.label}
                </a>
              </div>
              : <div className={classNames("uk-width-expand", styles.mobileSubMenuHeader)}>
                <span id={`mobile-navbar-${menuItem.label}`} className={classNames("uk-nav-header", styles.mobileMenuLink)}>
                  {menuItem.label}
                </span>
              </div>
            }
          </div>
        </li>
      );
      menuItem.subMenu.forEach(({ label, url, href},i)=> {
        rows.push(
          <li key={i}>
            <div className={classNames("uk-grid uk-grid-match", styles.mobileMenuHeaderRow)}>
              { url &&
                <div className={classNames("uk-width-expand", styles.mobileMenuHeader)}>
                  <a id={`mobile-navbar-${label}`} className={classNames(styles.mobileMenuLink)} href={"https://www.edo.com" +url}>
                    {label}
                  </a>
                </div>
              }
              { href &&
                <div className={classNames("uk-width-expand", styles.mobileMenuHeader)}>
                  <a id={`mobile-navbar-href-${label}`} href={href} className={classNames(styles.mobileMenuLink)}>
                    {label}
                  </a>
                </div>
              }
            </div>
          </li>
        );
      });
    }

    return rows;
  }, [subMenu])

  MENU.forEach(({ label, url, href, subMenu }, i) => {
    mobileMenuRows.push(
      <li key={i}>
        <div className={classNames("uk-grid uk-grid-match", styles.mobileMenuHeaderRow)}>
          { url &&
            <div className={classNames("uk-width-expand", styles.mobileMenuHeader)}>
              <a id={`mobile-navbar-${label}`} className={classNames("uk-nav-header", styles.mobileMenuLink)} href={"https://www.edo.com" + url}>
                {label}
              </a>
            </div>
          }
          { subMenu &&
            <span className="padding-left-0" onClick={() => {setSubMenu(url)}} >
              <i className={classNames(styles.mobileSubMenuArrow)} uk-icon={"icon: chevron-right;"} />
            </span>
          }
        </div>
      </li>
    );
  });

  return (
    <div className={styles.mobileMenuContainer}>
      { !subMenu ?
        <div className={classNames(subMenu === null ? "uk-animation-slide-top-small uk-animation-fast" : "uk-animation-slide-left uk-animation-fast", styles.mobileMenu)}>
        <div className={classNames(styles.dropdown)}>
          <ul>
            {mobileMenuRows}
          </ul>
        </div>
      </div>
      :
       <div className={classNames("uk-animation-slide-right ", styles.mobileMenu)}>
        <div className={classNames(styles.dropdown)}>
          <ul>
            {subMenuRows}
          </ul>
        </div>
      </div>
     }
    </div>
  )
};

export default Header;
