const processStyles: (styles: { [key: string]: string}) => { [key: string]: string} = (styles: { [key: string]: string}) => {
  const newStyles = {...styles};
  const camelize = (s: string) => s.replace(/-./g, x=>x[1].toUpperCase())
  Object.keys(styles).forEach((className: string) => {
    const newClassName = camelize(className);
    newStyles[newClassName] = styles[className]
  });
  return newStyles;
}

export default processStyles;
