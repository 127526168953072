import React, {useState} from "react";
import axios from "axios";
import oldStyles from "./styles.module.scss";
import classNames from "classnames";
import processStyles from "../../utils/processStyles";
import { ReactComponent as EmailIcon } from "../../images/mail-outline.svg";

const styles = processStyles(oldStyles);
declare global {
  interface Window {
    hbspt: any;
  }
}

export const submitHubspotForm = async (email) => {
  const portalId = "7151179";
  const formGuid = "1d34e9e9-f48c-4e04-83d8-e0cec1506c0d";
  const config = {
    headers: {
      'Content-Type': 'application/json',
    }
  }
  const creativeUrl = window?.location?.href || "";


  const response = await axios.post(`https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formGuid}`,
    {
      portalId,
      formGuid,
      fields: [
        {
          objectTypeId: "0-1",
          name: 'email',
          value: email,
        },
        {
          objectTypeId: "0-1",
          name: 'website',
          value: creativeUrl,
        },
      ],
      region: "na1",
      context: {
        pageUri: window?.location?.href || ""
      }
    },
    config
  );
  return response;
};

const EmailForm: React.FC<{}> = () => {
  const [email, setEmail] = useState("");
  const [submitted, setSubmitted] = useState(false);

  const onClick = () => {
    if (email.length > 0) {
      submitHubspotForm(email)
      setSubmitted(true);
    }
  }

  if (submitted) {
    return <div className={classNames("flex-row",styles.submittedContainer)}>
        <div className={styles.button}>
          We'll be in touch!
        </div>
    </div>;
  }

  return <>
      <EmailIcon className={styles.emailIcon} />
      <input className={styles.emailInput} type="text" id="email" value={email} placeholder="Enter Work Email Address" onChange={(e) => setEmail(e.target.value)} />
      <div className={styles.button} onClick={onClick}>
        Request A Demo
      </div>
  </>;
}

export default EmailForm;
