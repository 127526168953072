import React from "react";
import classNames from "classnames";
import oldStyles from "./index.module.scss";
import processStyles from "../../utils/processStyles";
const styles = processStyles(oldStyles);

const Button: React.FC<{className?: string, id?: string, href?: string, children?: React.ReactNode}> = (props)  => {
  const { className, id, href, children } = props;
  return <a id={id} href={href} className={classNames(className,styles.button)}>
      { children }
    </a>
}

export default Button;
