import React, { useState, useMemo } from "react";
import classNames from "classnames";
import oldStyles from "./index.module.scss";
import processStyles from "../../utils/processStyles";
import { getPathFromCategoryData, getPathFromBrandMetadata, fetchBrandData, CategoryData, CreativeMetadata } from "../../utils/ad_engage";
import CreativeCard from "../creative_card";
import * as Sentry from "@sentry/react";
const styles = processStyles(oldStyles);


const RelatedFooter: React.FC<{ relatedCategoryData?: Array<CategoryData>, creativeId?: string }> = (props)  => {
  const { relatedCategoryData, creativeId } = props;
  const [relatedAds, setRelatedAds] = useState<Array<CreativeMetadata>>([]);



  const relatedAdvertisers = useMemo(() => {
      const rA = relatedCategoryData?.map((c) => {
      c?.metrics.forEach((metric) => {
        metric.url = getPathFromCategoryData(c) + "/" + getPathFromBrandMetadata(metric);
      })
      return c?.metrics
    }
    ).flat().sort((a, b) => (b?.tv_ad_airings_count || 0) - (a?.tv_ad_airings_count || 0)).slice(0, 4);

    if (rA.length > 0) {
      Promise.all(rA.map((advertiser) => {
        return fetchBrandData(String(advertiser?.brand_id))
        .then((data) => {
          return data;
        })
        .catch((err) => {
          Sentry.captureException(err);
        });
      }))
      .then((data) => {
        const creatives = data.map((brandData) => {
          return brandData ? brandData?.creative_metrics.map((cm) => ({ ...cm, brandName: brandData?.brand_data?.brand_name})) : [];
        }).flat().sort((a, b) => (b?.tv_ad_airings_count || 0) - (a?.tv_ad_airings_count || 0)).filter((creative) => creative?.creative_id !== creativeId).slice(0,4);
        setRelatedAds(creatives);
      });
    }
    return rA
  }
  , [relatedCategoryData, creativeId]);


  return (<>
    { relatedAds.length > 0 ?
      <div className={classNames(styles.relatedCompetitorAds)}>
        <div className={styles.header}>
          Related Competitor Ads
        </div>
        <div className={classNames("uk-flex uk-flex-wrap uk-flex-between@l uk-child-width-1-4@l uk-child-width-1-2@m uk-child-width-1-1", styles.relatedAds)}>
          { relatedAds.map((relatedAd, i) => {
            return <CreativeCard creativeMetadata={relatedAd} first={i === 0} last={i === relatedAds.length - 1} />
          })}
        </div>
      </div>
      : null
    }
      <div className={classNames("flex-row uk-flex-between", styles.relatedAdvertisersCategories)}>
        <div className={classNames("flex-col", styles.columnContainer)}>
          <div className={styles.header}>
            Related Advertisers
          </div>
          <div className={classNames("flex-col", styles.relatedLinks)}>
            { relatedAdvertisers?.map((advertiser, i) => {
              
              return <a key={i} href={"https://edo.com/ad-engage/brand-rankings-tv-ad-creatives/" +advertiser?.url} target="_blank" rel="noreferrer">
                {advertiser?.brand_name}
                </a>
              })}
          </div>
        </div>
        <div className={classNames("flex-col", styles.columnContainer)}>
          <div className={styles.header}>
            Related Categories
          </div>
          <div className={classNames("flex-col", styles.relatedLinks)}>
            {relatedCategoryData?.map((category, i) => {
              
              return <a key={i} href={"https://edo.com/ad-engage/brand-rankings-tv-ad-creatives/" + getPathFromCategoryData(category)} target="_blank" rel="noreferrer">
                {category?.name}
                </a>
            })}
          </div>
        </div>
      </div>
    </>
  )
}

export default RelatedFooter;
