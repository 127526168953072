import axios from "axios";

// data loading doc
// https://edoinc.atlassian.net/wiki/spaces/~517155634/pages/2137817099/Public+Ad+Engage+Data+Loading

export const dataLoadingPath = process.env.EDO_ENV === "production" ? "https://production-marketing.edo.com/data/" : "https://staging-marketing.edo.com/data/"; 
export const adEngagePath = "/ad-engage/brand-rankings-tv-ad-creatives";
export const playerPath = process.env.EDO_ENV === "production" ? "https://player.edo.com/public/watch/" : "https://staging-player.edo.com/public/watch/";

export interface CategoryMetadata {
  name: string;
  category_ids?: Array<number>;
}
export interface CategoryMetadataFile {
  [index: string]: CategoryMetadata;
}

export interface CategoryData {
  public_id?: number;
  name?: string;
  metrics?: Array<BrandMetadata>;
  platform_share_top_ten?: Array<BrandMetadata>;
  platform_share_total_records?: number;
  linear?: number;
  hulu?: number;
}

export interface BrandMetadata {
  brand_id?: number;
  brand_name?: string;
  image_url?: string | null;
  tv_ad_airings_count?: number | null;
  public_id?: number;
  category_id?: number;
  category_name?: string;
  url: string;
}

export interface CreativeMetadata {
  creative_id: string;
  creative_title?: string | null;
  tv_ad_airings_count?: number | null;
  thumbnail_url?: string | null;
  duration?: number;
  brandName?: string;
}

export interface BrandData {
  brand_data: BrandMetadata;
  creative_metrics: Array<CreativeMetadata>;
  platform_share?: { linear: number, hulu: number };
}

export const DEFAULT_HEADERS = {
  'Cache-Control': 'max-age=43200, must-revalidate', // 43200 seconds -> 12 hours
  'Accept': "application/json, text/plain, */*",
}

export const fetchCategoriesMetaData = (): Promise<CategoryMetadataFile> => {
  return axios.get(
    dataLoadingPath + "categories_metadata.json",
    { headers: DEFAULT_HEADERS }
  )
    .then((res) => {
      if (typeof res.data != "object") {
        throw new Error("No data");
      }
      if (res.data) {
        /* TODO: check for correct meta data */
        return res.data
      }
    })
}

export const fetchCategoryData = async (categoryId: string): Promise<CategoryData> => {
  return axios
    .get(
      dataLoadingPath + `categories/category_${categoryId}_metrics.json`,
      { headers: DEFAULT_HEADERS }
    )
    .then((res) => {
      if (typeof res?.data != "object") {
        console.log("No Data");
        throw new Error("No data");
      }
      if (res?.data) {
        // add check for correct category slug
      }
      // need to set after setBrandData to avoid showing empty state early
      return res?.data
    })
}

export const fetchBrandData = async (brandId: string): Promise<BrandData> => {
  return axios
    .get(
      dataLoadingPath + `brands/brand_${brandId}_metrics.json`,
      { headers: DEFAULT_HEADERS }
    )
    .then((res) => {
      if (typeof res?.data != "object") {
        throw new Error("No data");
      }

      if (res?.data) {
        // add check for correct brand slug
      }

      return res.data
    })
}


export const getPathFromCategoryMetadata = (categoryId: string, categoryData: CategoryMetadata) => {
  return categoryId + "_" + categoryData?.name?.replace(/[^a-z0-9]/gi,'');
}

export const getPathFromCategoryData = (categoryData: CategoryData) => {
  return categoryData.public_id + "_" + categoryData?.name?.replace(/[^a-z0-9]/gi,'');
}


export const getPathFromBrandMetadata = (brandData: BrandMetadata) => {
  return brandData?.brand_id + "_" + brandData?.brand_name?.replace(/[^a-z0-9]/gi,'');
}

export const getDashboardLink = (dashboardId?: number) => {
  return dashboardId ? `https://dashboard.edo.com/jarvis/creatives/overview/${dashboardId}/`: "https://dashboard.edo.com/jarvis";
}

