import React from "react";
import classNames from "classnames";
import oldStyles from "./index.module.scss";
import processStyles from "../../utils/processStyles";
import { CreativeMetadata, playerPath } from "../../utils/ad_engage";
import edoLogoHorizontal from "../../images/edo-logo-horizontal-grey.svg";

const styles = processStyles(oldStyles);

const CreativeCard: React.FC<{ creativeMetadata: CreativeMetadata, first?: boolean, last?: boolean}> = (props)  => {
  const { creative_id, creative_title, duration, thumbnail_url, brandName } = props.creativeMetadata;
  const publicPlayerUrl = creative_id ? playerPath + creative_id : "";

  const { first = false, last = false } = props;
  return <a className={classNames("flex-col", styles.creativeCard)} href={publicPlayerUrl} target="_blank" rel="noreferrer">
    <div className={classNames(styles.innerContainer, { [styles.first]: first, [styles.last]: last})}>
      <div className={styles.title}>
        { creative_title === creative_id ? brandName : creative_title }
      </div>
      <div className={styles.thumbnailContainer}>
        <img
          alt={creative_id}
          className={!thumbnail_url ? styles.defaultIcon : ""}
          src={thumbnail_url || edoLogoHorizontal}
        />
      </div>
      <div className={classNames("flex-row", styles.creativeDetail)}>
        <span className={styles.creativeId}>
          {creative_id}
        </span>
        <span className={styles.duration}>
          {duration + "s"}
        </span>
      </div>
      {/* <div className={classNames("flex-row", styles.airingsDetail)}>
        <span className={styles.airingsLabel}>
          Airings:&nbsp;
        </span>
        <span className={styles.airingsCount}>
          {tv_ad_airings_count}
        </span>
      </div> */}

    </div>

  </a>
}

export default CreativeCard;
